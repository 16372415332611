import { age } from '../utils/index'

export const seo = {
  url: 'about',
  title: {
    en: `Discover one of the best forwarding in Poland. ${age}-years of experience in the industry`,
    es: `Conozca a uno de los mejores transportistas de Polonia. ${age} años de experiencia en la industria`,
    ro: `Descoperiți una dintre cele mai bune expedieri din Polonia. ${age}-ani de experiență în industrie`,
  },
  desc: {
    en: 'Omida VLS – international transport and freight forwarding with years of experience. Discover our story!',
    es: 'Omida VLS – transporte internacional y forwarder con muchos años de experiencia. Conozca nuestra historia!',
    ro: 'Omida VLS – transport internațional și expediere cu mulți ani de experiență. Descoperiți istoria noastră!',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    en: 'Discover Us',
    es: 'Descúbrenos',
    ro: 'Descoperiți-ne',
  },
  desc: {
    en: `Welcome to Omida VLS - the most recognizable Polish brand of TFL.`,
    es: 'Conozca a Omida VLS, la marca polaca más reconocible de TFL',
    ro: `Bine ați venit la Omida VLS - cel mai cunoscut brand polonez al TFL`,
  },
  button: {
    text: {
      en: 'Read more',
      es: 'Leer más',
      ro: 'Citește mai mult',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        en: 'Omida VLS – international transport and freight forwarding with years of experience. Discover our story!',
        es: 'Omida VLS – transporte internacional y forwarder con muchos años de experiencia. Conozca nuestra historia!',
        ro: 'Omida VLS – transport internațional și expediere cu mulți ani de experiență. Descoperiți istoria noastră!',
      },
      texts: [
        {
          en: `The brand structure consists of over 30 branches throughout Poland and Europe, represented by over 100 experienced forwarders and a network of several hundred domestic and European carriers.`,
          es: 'Líder polaco del transporte por carretera en Polonia y Europa. Omida VLS es una marca que forma parte del grupo Omida VLS Sp. z o.o. se estableció en 2010. Nuestra estructura consta de 20 sucursales en toda Polonia, que está representada por más de 200 transitarios y una red de varios cientos de transportistas en el país y Europa.',
          ro: `Suntem o companie poloneză care este lider în transportul rutier atât în ​​Polonia, cât și în Europa. Omida VLS este un brand al Omida VLS Sp. z o.o. grup, care a fost înființat în 2010. Structura noastră este formată din 20 de sucursale situate în toată Polonia, care este reprezentată de peste 200 de expeditori, și o rețea de câteva sute de transportatori în Polonia și Europa.`,
        },
        {
          en: `Our history is one of continuous development and striving to be a leader in the logistics industry, built on the solid foundations of the vision and commitment of our founders.`,
          es: `Hemos estado implementando la estrategia de desarrollo dinámico continuamente durante ${age} años. Los fundadores de Omida VLS son Bartłomiej Glinka y Marek Rostkowski. La sede de Omida VLS está ubicada en el centro de negocios de Gdańsk - Olivia Center, en el edificio Olivia Star.`,
          ro: `De-a lungul acestor ani de ${age}, am implementat continuu strategia de dezvoltare dinamică. Fondatorii Omida VLS sunt Bartłomiej Glinka și Marek Rostkowski. Sediul Omida VLS este situat în Centrul Olivia din clădirea Olivia Star.`,
        },
      ],
    },
    {
      headline: {
        en: 'History of the Polish transport company - Omida VLS',
        es: 'Historia de la empresa de transporte polaca - Omida VLS',
        ro: 'Istoria companiei poloneze de transport - Omida VLS',
      },
      texts: [
        {
          en: 'Omida VLS was created from the merger of three dynamic companies belonging to the OMIDA Group portfolio: <strong>Omida Logistics</strong>, <strong>Virtus Logistics</strong> and <strong>Cargonite</strong>. The cooperation of these entities allowed the creation of an organization that combines experience, knowledge and an innovative approach to logistics.',
          es: 'Nuestra historia comenzó en <strong>Gdańsk</strong>. Los fundadores de nuestra empresa, <strong>Bartłomiej Glinka</strong> y <strong>Marek Rostkowski</strong>, abren Omida VLS y crean una sucursal de varias personas en la oficina con vistas a Westerplatte. En 3 años creamos varias sucursales en <strong>Varsovia</strong>, <strong>Poznań</strong>, <strong>Katowice</strong> y <strong>Sopot</strong>. Guiados por el principio de que Omida son personas, después de 5 años de funcionamiento en nuestra empresa, empleamos a <strong>un equipo de más de 100 especialistas</strong>. En 2015, trasladamos nuestra sede al centro de negocios más moderno de Pomerania: <strong>Olivia Center en Gdańsk</strong>.',
          ro: '<span>Povestea noastră a început în <strong>Gdańsk</strong>. Fondatorii companiei noastre, <strong>Bartłomiej Glinka</strong> și <strong>Marek Rostkowski</strong>, deschid Omida VLS și creează o sucursală cu mai mulți oameni în birou cu vedere la Westerplatte. În 3 ani am creat mai multe filiale în <strong>Varșovia</strong>, <strong>Poznań</strong>, <strong>Katowice</strong> și <strong>Sopot</strong>. Ghidați de principiul că Omida este oameni, după 5 ani de funcționare în compania noastră, am angajat o <strong>echipă de peste 100 de specialiști</strong>. În 2015, ne-am mutat sediul în cel mai modern centru de afaceri din Pomerania - <strong>Olivia Center din Gdańsk</strong>.</span>',
        },
        {
          en: 'Thanks to the unique career path, the leaders of individual branches have created a structure of partner companies specializing in transport niches such as: <strong>oversized transport</strong>, <strong>military transport</strong>, <strong>sea transport</strong>, <strong>air transport</strong>, <strong>transport in the Scandinavian countries</strong>, <strong>high value transport</strong>.',
          es: 'Gracias a la trayectoria profesional única, los líderes de las ramas individuales han creado una estructura de empresas asociadas que se especializan en nichos de transporte tales como: <strong>transporte sobredimensionado</strong>, <strong>transporte militar</strong>, <strong>transporte marítimo</strong>, <strong>transporte aéreo</strong>, <strong>transporte en los países escandinavos</strong>, <strong>transporte de alto valor</strong>.',
          ro: 'Datorită carierei unice, liderii sucursalelor individuale au creat o structură de companii partenere specializate în nișe de transport precum: <strong>transport supradimensionat</strong>, <strong>transport militar</strong>, <strong>transport maritim</strong>, <strong>transport aerian</strong>, <strong>transport în țările scandinave</strong>, <strong>transport de mare valoare</strong>.',
        },
        {
          en: `As part of <strong>our cooperation with <a href="https://omida-group.com/en/company/omida7rsolutions/" target="_blank">Omida 7R Solutions</a></strong>, we provide our Clients with access to modern warehouse services, including storage of goods and full logistics services tailored to individual needs. Thanks to this, we support the optimization of the supply chain and effective inventory management.`,
          es: `En 2019, abrimos la <a href="/contract-logistics/">revista OMIDA Group en Wypędy, cerca de Varsovia</a> y en 2022 decidimos <strong>duplicando el área de espacio de almacenamiento</strong>, en el que brindamos todos los servicios relacionados con el manejo de procesos logísticos para <a href="/ecommerce/">comercio electrónico</a> historias.`,
          ro: `În 2019, am deschis <a href="/contract-logistics/">depozitul OMIDA Group în Wypędy lângă Varșovia </a>și în 2022 am decis să <strong>dublăm suprafața spațiului de depozitare</strong>, în care oferim toate serviciile legate de gestionarea proceselor logistice pentru magazinele <a href="/ecommerce/">ecommerce</a>.`
        },
      ],
    },
    {
      headline: {
        en: 'Gain an experienced transport partner in Poland and Europe',
        es: 'Consiga un socio de transporte con experiencia en Polonia y Europa',
        ro: 'Obțineți un partener de transport cu experiență în Polonia și Europa',
      },
      texts: [
        {
          en: `
          We specialize in <a href="/ftl-transport/">FTL road transport</a>. We organize <a href="/global/">transport throughout Europe</a>. Thanks to our branches, we provide transport for the industry:
          <ul>
            <li>automotive</li>
            <li>furniture</li>
            <li>production</li>
            <li>construction</li>
            <li>grocery</li>
            <li>electronic</li>
            <li>gaming</li>
            <li>clothing</li>
            <li>chemical</li>
            <li><a href="https://omida.pl/transport-branze/">and many more</a></li>
          </ul>
          `,
          es: `
           Somos especialistas en <a href="/ftl-transport/">transporte FTL por carretera</a>. Organizamos <a href="/global/">transporte por toda Europa</a>. Gracias a nuestras sucursales brindamos transporte para la industria:
           <ul>
             <li>automoción</li>
             <li>muebles</li>
             <li>producción</li>
             <li>construcción</li>
             <li>comestibles</li>
             <li>electrónica</li>
             <li>juegos</li>
             <li>ropa</li>
             <li>químico</li>
             <li><a href="https://omida.pl/transport-branze/">y muchos más</a></li>
           </ul>
          `,
          ro: `

            Suntem specializați în <a href="/ftl-transport/">transport rutier FTL</a>. Organizam <a href="/global/">transport în toată Europa</a>. Datorită filialelor noastre, asigurăm transport pentru industrie:
            <ul>
              <li>auto</li>
              <li>mobilier</li>
              <li>producție</li>
              <li>construcții</li>
              <li>panificație</li>
              <li>electronic</li>
              <li>jocuri</li>
              <li>îmbrăcăminte</li>
              <li>chimic</li>
              <li><a href="https://omida.pl/transport-branze/">și multe altele</a></li>
            </ul>

          `,
        },
        {
          en: `In addition to road FTL transport and <a href="/ltl-transport/">groupage LTL transport</a> we provide ecological <a href="/intermodal/">intermodal</a>, oversized, high value and military transport.`,
          es: `Además del transporte FTL por carretera y <a href="/ltl-transport/">transporte LTL de grupaje</a>, ofrecemos <a href="/intermodal/">transporte intermodal</a ecológico ><br/>y transporte de rollos de papel - <a href="/paperliner/">paperliner</a>.`,
          ro: `Pe lângă transportul rutier FTL și <a href="/ltl-transport/">transport LTL în grup</a>, oferim <a href="/intermodal/">transport intermodal</a> ecologic<br/>și transportul rolelor de hârtie - <a href="/paperliner/">paperliner</a>.`,
        },
        {
          en: `
          <span>Our portfolio includes companies such as:
           <strong>Electrolux</strong>,
           <strong>Kreisler</strong>,
           <strong>Michelin</strong>,
           <strong>Procter and Gamble</strong>,
           <strong>Unilever</strong>,
           <strong>Żabka</strong>,
           <strong>Żywiec Zdrój</strong>
           and many other Polish and international companies.</span>
           `,
          es: `
           <span>Nuestra cartera incluye empresas como:
            <strong>Electrolux</strong>,
            <strong>Kreisler</strong>,
            <strong>Michelín</strong>,
            <strong>Procter and Gamble</strong>,
            <strong>Unilever</strong>,
            <strong>Żabka</strong>,
            <strong>Żywiec Zdroj</strong>
            y muchas otras empresas polacas e internacionales.</span>
            `,
          ro: `
          <span>Portofoliul nostru include companii precum:
            <strong>Electrolux</strong>,
            <strong>Kreisler</strong>,
            <strong>Michelin</strong>,
            <strong>Procter and Gamble</strong>,
            <strong>Unilever</strong>,
            <strong>Żabka</strong>,
            <strong>Żywiec Zdrój</strong>
            și multe alte companii poloneze și internaționale.</span>
            `,
        },
      ],
    },
  ],
}

export const video = {
  title: {
    en: '',
    es: '',
    ro: '',
    // en: 'Omida 10 Years Together',
    // es: 'Omida 10 Years Together',
  },
  desc: {
    en: '',
    es: '',
    ro: '',
    // en: "(October 2020) Many hours of planning, recording and video editing. All this to summarize the 10 years in the TFL industry to date. Marek Rostkowski and Bartłomiej Glinka, Presidents of Omida VLS Sp. z o.o., told about how #OmidaGroup was created and what decisions had to be made to become one of the most recognizable brands in the TFL industry in Poland. This is a special time for our company, we encourage you to comment and share - let's show together how strong the OMIDA Group brand is.",
    // es: '(Octubre 2020) Muchas horas de planificación, grabación y edición de vídeo. Todo esto para resumir los 10 años en la industria TFL hasta la fecha. Marek Rostkowski y Bartłomiej Glinka, presidentes de Omida VLS Sp. z o.o., contaron cómo se creó #OmidaGroup y qué decisiones se tuvieron que tomar para convertirse en una de las marcas más reconocidas en la industria de TFL en Polonia. Este es un momento especial para nuestra empresa, lo alentamos a comentar y compartir: mostremos juntos cuán fuerte es la marca del Grupo Omida.',
  },
  url: 'https://www.youtube.com/embed/P3S5QVpjWmI',
}
