import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import BannerImage from '../../components/BannerImage'

const ChiefSection = ({ chiefs }) => {
  const { lang } = useLangContext()
  const query = useStaticQuery(graphql`
    {
      image1: file(
        absolutePath: { regex: "/images/about/marek_rostkowski.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image2: file(
        absolutePath: { regex: "/images/about/bartlomiej_glinka.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image3: file(
        absolutePath: { regex: "/images/about/daniel_franke.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image4: file(
        absolutePath: { regex: "/images/about/szymon_rusztyn.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image5: file(
        absolutePath: { regex: "/images/about/michal_pikura.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image6: file(
        absolutePath: { regex: "/images/about/maciej_daniluk.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <>
      <Section top>
        <Headline
          h={2}
          title={chiefs.title[lang]}
          large
          gradient
        />
      </Section>
      <Section mobile bottom>
        {chiefs.data.map(({ name, title, desc, image }, id) => (
          <BannerImage
            square
            title={name}
            subtitle={title}
            desc={desc[lang]}
            image={query[image]}
            key={id}
            h={3}
          />
        ))}
      </Section>
    </>
  )
}

export default ChiefSection
