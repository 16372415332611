import React from 'react'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import Button from '../../components/Button'
import { colors, s, sDesc, sTextGradientBlack } from '../../style'
import alt from '../../content/alt'

import CWB from '../../images/about/certificates/CWB.svg'
import GMPPlus from '../../images/about/certificates/GMP_Plus.svg'
import IFS_Logistics from '../../images/about/certificates/IFS_Logistics.svg'
import IFS_Broker from '../../images/about/certificates/IFS_Broker.svg'
import ISO_28000 from '../../images/about/certificates/ISO_28000.svg'
import WSK from '../../images/about/certificates/WSK.svg'

const svgs = {
  CWB,
  GMPPlus,
  IFS_Logistics,
  IFS_Broker,
  ISO_28000,
  WSK,
}

const content = {
  title: {
    en: 'Certificates',
    es: 'Certificados',
    ro: 'Certificate',
  },
  desc: {
    en: `
    Quality certificates are an important element of our organization. Thanks to the certificates we have, you can be sure of high standards both in the areas of service and transport services. Meeting high expectations is what distinguishes us on the market. At the time of the acquisition, the certificates remain valid, and letters requesting updates have been sent to the institutions issuing the following certifications.
    `,
    es: `
    Los certificados de calidad son un elemento importante de nuestra organización. Gracias a los certificados que tenemos, puede estar seguro de los altos estándares tanto en las áreas de servicio como en los servicios de transporte. Cumplir con las altas expectativas es lo que nos distingue en el mercado. En el momento de la adquisición, los certificados permanecen válidos, y se han enviado cartas solicitando actualizaciones a las instituciones que emiten las siguientes certificaciones.
    `,
    ro: `
    Certificatele de calitate sunt un element important al organizației noastre. Datorită certificatelor pe care le deținem, puteți fi siguri de standarde înalte atât în domeniile serviciilor, cât și în serviciile de transport. Îndeplinirea așteptărilor ridicate este ceea ce ne distinge pe piață. La momentul achiziției, certificatele rămân valabile, iar scrisorile care solicită actualizări au fost trimise instituțiilor care emit următoarele certificări.
    `,
  },
  data: [
    {
      title: 'CWB',
      icon: 'CWB',
      desc: {
        en: 'The certificate of business credibility is a confirmation of the highest financial condition, which is received by particularly distinguished companies that meet very selected criteria. It is a strong guarantee of stability and profitability that we offer.',
        es: 'El certificado de credibilidad empresarial es una confirmación de la más alta condición financiera, que reciben empresas particularmente distinguidas que cumplen con criterios muy seleccionados. Es una fuerte garantía de estabilidad y rentabilidad la que ofrecemos.',
        ro: 'Certificatul de credibilitate în afaceri este o confirmare a celei mai înalte situații financiare, care este primit de companii deosebit de distinse care îndeplinesc criterii foarte selectate. Este o garanție puternică de stabilitate și rentabilitate pe care o oferim.',
      },
    },
    {
      title: 'GMP+ B4',
      icon: 'GMPPlus',
      desc: {
        en: 'GMP + is an international certification system that guarantees feed safety. Standard of Good Practices for the Production and Transport of Feed.',
        es: 'GMP+ es un sistema de certificación internacional que garantiza la seguridad de los piensos. Norma de Buenas Prácticas para la Producción y Transporte de Piensos.',
        ro: 'GMP+ este un sistem internațional de certificare care garantează siguranța furajelor. Standard de bune practici pentru producerea și transportul furajelor.',
      },
      links: [
        { text: { pl: 'EN', en: 'EN' }, link: '/pdf/cert/gmp_en.pdf' },
      ],
    },
    {
      title: 'WSK',
      icon: 'WSK',
      desc: {
        pl: 'Certyfikat WSK to dokument potwierdzający posiadanie i stosowanie skutecznego Wewnętrznego Systemu Kontroli wymaganego w firmach uczestniczących w międzynarodowym obrocie produktami podwójnego zastosowania oraz uzbrojeniem.',
        en: 'The WSK certificate is a document confirming the possession and application of an effective Internal Control System required in companies participating in international trade in dual-use products and armaments.',
      },
      links: [
        { text: { pl: 'PL', en: 'PL' }, link: '/pdf/cert/wsk_pl.pdf' },
      ],
    },
    {
      title: 'ISO 28000',
      icon: 'ISO_28000',
      desc: {
        en: 'ISO 28000 is an international standard for supply chain security management system. Thanks to him, all our deliveries are made on time.',
        es: 'ISO 28000 es un estándar internacional para la gestión de la seguridad de la cadena de suministro. Gracias a él, todas nuestras entregas se realizan a tiempo.',
        ro: 'ISO 28000 este un standard internațional pentru sistemul de management al securității lanțului de aprovizionare. Datorită lui, toate livrările noastre se fac la timp.',
      },
      links: [
        { text: { en: 'PL', es: 'PL', ro: 'PL' }, link: '/pdf/cert/iso28000_pl.pdf' },
        { text: { en: 'EN', es: 'EN', ro: 'EN' }, link: '/pdf/cert/iso28000_en.pdf' },
      ],
    },
    {
      title: 'ISO 9001',
      icon: '',
      desc: {
        pl: 'Międzynarodowy standard określający wymagania, które powinny spełniać Systemy Zarządzania Jakością w organizacji. System Zarządzania Jakością zgodny z normą PN-EN ISO 9001:2015 -10 ma na celu zapewnienie spełnienia wymagań klientów i stałą poprawę jakości wyrobów i/lub świadczonych usług.',
        en: 'International standard specifying the requirements that Quality Management Systems in an organization should meet. The Quality Management System compliant with the PN-EN ISO 9001:2015 -10 standard aims to ensure that customer requirements are met and to continuously improve the quality of products and/or services provided.',
      },
      links: [
        { text: { pl: 'PL', en: 'PL' }, link: '/pdf/cert/iso9001_pl.pdf' },
        { text: { pl: 'EN', en: 'EN' }, link: '/pdf/cert/iso9001_en.pdf' },
      ],
    },
    {
      title: 'IFS Logistics',
      icon: 'IFS_Logistics',
      desc: {
        en: 'In Linkpoint company, IFS Logistics system of food safety during transportation was implemented and certified. It makes it possible to monitor transportation’s consistency with food sanitary regulations, use resources more effectively and increase the scope of goods transported.',
        es: 'Sistema certificado de seguridad alimentaria durante el transporte IFS Logística. Esto le permite monitorear el cumplimiento del transporte con las regulaciones alimentarias, utilizar los recursos de manera más eficiente y ampliar la gama de bienes transportados.',
        ro: 'În compania Linkpoint a fost implementat și certificat sistemul IFS Logistics de siguranță alimentară în timpul transportului. Face posibilă monitorizarea coerenței transportului cu reglementările sanitare alimentare, utilizarea mai eficientă a resurselor și creșterea volumului mărfurilor transportate.',
      },
      links: [
        { text: { en: 'PL', es: 'PL', ro: 'PL' }, link: '/pdf/cert/ifslogistics_pl.pdf' },
        { text: { en: 'EN', es: 'EN', ro: 'EN' }, link: '/pdf/cert/ifslogistics_en.pdf' },
        { text: { en: 'DE', es: 'DE', ro: 'DE' }, link: '/pdf/cert/ifslogistics_de.pdf' },
      ],
    },
    {
      title: 'IFS Broker',
      icon: 'IFS_Broker',
      desc: {
        pl: 'Certyfikat IFS Broker to dokument potwierdzający, że firma pełniąca rolę pośrednika w łańcuchu dostaw spełnia wymagania dotyczące bezpieczeństwa, jakości i zgodności z przepisami. Certyfikat ten gwarantuje kontrolę procesów handlowych oraz zapewnia, że dostarczane produkty odpowiadają oczekiwaniom klientów i wymogom rynku międzynarodowego.',
        en: 'The IFS Broker certificate is a document confirming that a company acting as an intermediary in the supply chain meets the requirements for safety, quality and compliance with regulations. This certificate guarantees control of trade processes and ensures that the products delivered meet customer expectations and the requirements of the international market.',
      },
      links: [
        { text: { pl: 'PL', en: 'PL' }, link: '/pdf/cert/ifsbroker_pl.pdf' },
        { text: { pl: 'EN', en: 'EN' }, link: '/pdf/cert/ifsbroker_en.pdf' },
      ],
    },
  ],
}

const Certificates = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Section top bottom id='certificates'>
        <Headline
          h={2}
          title={content.title[lang]}
          desc={content.desc[lang]}
          large
          gradient
        />
        <div css={sContainer}>
          {content.data.map(({ title, desc, icon, links }, id) => (
            <div css={sCert} key={id}>
              {icon && <img css={sImage} key={id} src={svgs[icon]} alt={title + alt} />}
              <div css={sText}>
                <h3 css={sTitle}>{title}</h3>
                <p css={[sDesc, { fontSize: '0.75rem' }]}>{desc[lang]}</p>
                <div
                  css={{
                    display: 'flex',
                    marginTop: '1.5rem',
                    [s.sm_down]: { justifyContent: 'space-between' },
                  }}>
                  {links?.map(({ text, link }, id) => (
                    <Button
                      noMargin
                      small
                      key={id}
                      link={link}
                      extraCss={{
                        marginRight: '1rem',
                      }}>
                      {text[lang]}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Section>
    </>
  )
}

const sContainer = {
  display: 'grid',
  marginTop: '4rem',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '2rem',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}

const sCert = {
  backgroundColor: colors.greyLighter,
  display: 'flex',
  [s.xs]: {
    padding: '1rem 2rem 2rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '1rem',
  },
  [s.sm]: { borderRadius: '1rem', padding: '2rem 2rem 2rem 1rem' },
  [s.md_only]: {
    flexDirection: 'column',
    padding: '1rem 2rem 4rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [s.md]: {
    borderRadius: '2rem',
  },
  [s.lg]: { padding: '4rem 2rem' },
}

const sImage = {
  [s.md_down]: {
    flexBasis: '50%',
    maxWidth: '50%',
  },

  [s.sm_only]: {
    flexBasis: '25%',
    maxWidth: '25%',
  },

  [s.lg]: {
    flexBasis: '25%',
    maxWidth: '25%',
  },
}

const sText = {
  [s.sm_only]: {
    paddingLeft: '1rem',
  },
  [s.lg]: {
    flexBasis: '75%',
    maxWidth: '75%',
    paddingLeft: '2rem',
  },
}

const sTitle = {
  ...sTextGradientBlack,
  fontSize: '1.5rem',
  marginBottom: '1rem',
  fontWeight: 700,
  width: 'max-content',
}

export default Certificates
