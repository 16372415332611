import React from 'react'
import { useLangContext } from '../../context/lang.context'
import ChiefSection from './ChiefSection'

const content = {
  managment: {
    title: {
      en: 'Management Board',
    },
    data: [
      {
        name: 'Szymon Rusztyn',
        title: 'CEO',
        desc: {
          en: `Szymon Rusztyn has been involved in the TSL industry for over 18 years, creating modern solutions in operations management. He started his career from an operational position as a freight forwarder. He quickly gained experience, progressing through successive levels of professional development. Thanks to consistent work and strategic skills, he has built one of the leading freight forwarding companies on the Polish market - Virtus Logistics, employing 180 employees in several branches. His priority is the joint operation of all partner companies and dynamic development on new markets.`,
        },
        image: 'image4',
      },
      {
        name: 'Michał Pikura',
        title: 'Member of the Management Board',
        desc: {
          en: `Michał Pikura is a practitioner and specialist with 15 years of experience in the TSL industry, having started his career as an international freight forwarder. After joining Virtus Logistics, he was promoted to Regional Director due to his commitment and professional development. He managed teams and supported the development of organizational structures, with a focus on creating efficient processes and building relationships with key customers. At Omida VLS, he is responsible for coordinating integration processes and strategic development. His analytical thinking and commitment to streamlining operations will allow the brand to achieve the highest level of operational efficiency.`,
        },
        image: 'image5',
      },
      {
        name: 'Maciej Daniluk',
        title: 'Member of the Management Board',
        desc: {
          en: `Maciej Daniluk, with nearly 10 years of experience in financial controlling, has been associated with OMIDA Group since 2015. He worked as a Business Controller and was responsible for reporting, financial controlling and development of operational processes. His commitment and knowledge allowed him to implement numerous financial improvements in the Group. At Omida VLS, he oversees strategic financial management and the development of processes to support operations.`,
        },
        image: 'image6',
      },
    ],
  },
  supervisory: {
    title: {
      en: 'Supervisory Board',
    },
    data: [
      {
        name: 'Marek Rostkowski',
        title: 'Member of the Supervisory Board',
        desc: {
          en: `Marek Rostkowski has been associated with the TSL industry since 2002. Graduate of MBA studies at the WSB University in Gdańsk. Co-founder of OMIDA Group. As the President of the Management Board, in just 8 years, he led the company to the top 10 largest companies in the industry in the country. Today, OMIDA Group is a group of several companies employing nearly 1,000 across the country. As a mentor at the Columbus Foundation and a leader of the development program for high school youth, The Grade, he shares his experience, inspiring the next generations of leaders. He is a co-founder of the OMIDA Yacht Club, which promotes sailing among the local community.`,
        },
        image: 'image1',
      },
      {
        name: 'Bartłomiej Glinka',
        title: 'Member of the Supervisory Board',
        desc: {
          en: `Bartłomiej Glinka has been involved in the TSL industry for over 20 years. Since 2010, he has successfully managed the Omida group of companies, thus creating one of the fastest growing TSL companies in Poland. A graduate of the Faculty of Economics at the University of Gdańsk, with a master's degree in Maritime Trade and a graduate of MBA studies at the WSB University in Gdańsk. He also serves as a member of the Council of Experts at the University of Gdańsk. He is a co-founder and president of the Columbus Foundation, whose main goal is to educate young people and business leaders. There, he shares his knowledge and experience with future entrepreneurs. He is a mentor and one of the founders of Columbus High School.`,
        },
        image: 'image2',
      },
      {
        name: 'Daniel Franke',
        title: 'Member of the Supervisory Board',
        desc: {
          en: `Daniel Franke has been involved in the logistics industry for over 20 years, gaining experience both in Poland and abroad. He has carried out many international projects, developing the logistics division of one of the world's industry leaders in Western and Southern Europe. In 2022, he joined the OMIDA Group as Managing Director and Member of the Management Board. He is responsible for implementing the strategy, focusing on the development of contract logistics, international expansion and the digitization of operational processes. His experience and knowledge support the OMIDA Group in achieving strategic business goals, including merger and acquisition (M&A) activities.`,
        },
        image: 'image3',
      },
    ],
  }
}

const contentArchive = {
  title: {
    en: 'Management',
    es: 'Gestión',
    ro: 'Conducerea',
  },
  data: [
    {
      name: 'Marek Rostkowski',
      title: 'CEO OMIDA Group',
      desc: {
        en: 'He has been a part of the TFL industry since 2002. A graduate of MBA studies at the WSB University in Gdańsk. Co-founder and President of Omida VLS Sp. z o.o. He is motivated by the ever continuing success in business. He believes that an important element of every person`s development is self-improvement, which is why is a mentor in the Columbus Foundation and a development program for high school students known as The Grade. As the leader of OMIDA Group, he supports activities aimed at the development of employee competence and integration. He is involved in many local initiatives. He lives by the motto that "by learning and applying your knowledge, you can solve every problem, overcome every obstacle and achieve every goal you set"',
        es: 'Desde 2002, ha estado asociado con la industria de TFL. Graduado de los estudios de MBA en la Universidad WSB en Gdańsk. Co-fundador y Presidente de Omida VLS Sp. z o.o. Lo motivan los éxitos sucesivos. Cree que un elemento importante del desarrollo de todo ser humano es la posibilidad de superación personal, por lo que es mentor de la Fundación Colón y del programa de desarrollo de bachillerato Grado. Como director del Grupo Omida, apoya actividades para el desarrollo de las competencias y la integración de los empleados. Involucrado en muchas iniciativas locales. Se guía por el lema de que "aprendiendo y aplicando los conocimientos adquiridos, puede resolver cualquier problema, superar cualquier obstáculo y lograr cualquier meta que se proponga".',
        ro: 'El face parte din industria TFL din 2002. Absolvent al studiilor MBA la Universitatea WSB din Gdańsk. Co-fondator și președinte al Omida VLS Sp. z o.o. El este motivat de succesul continuu în afaceri. El crede că un element important al dezvoltării fiecărei persoane este auto-îmbunătățirea, motiv pentru care este mentor în Fundația Columbus și un program de dezvoltare pentru elevii de liceu cunoscut sub numele de The Grade. În calitate de lider al Grupului Omida, el sprijină activități care vizează dezvoltarea competenței și integrării angajaților. Este implicat în multe inițiative locale. El trăiește după motto-ul că „prin învățarea și aplicarea cunoștințelor tale, poți rezolva orice problemă, poți depăși orice obstacol și poți atinge fiecare obiectiv pe care ți-l stabilești".',
      },
      image: 'image1',
    },
    {
      name: 'Bartłomiej Glinka',
      title: 'CEO OMIDA Group',
      desc: {
        en: 'Graduate of the Faculty of Economics at the University of Gdansk. He graduated with a major in Maritime Trade. A graduate of MBA studies at the WSB University in Gdańsk. Member of the Expert Council of the University of Gdansk. Associated with the TFL industry for over 20 years. Since 2010, he has been successfully managing a group of companies Omida VLS Sp. z o.o., a Pomeranian leader in the TFL industry employing over 500 employees. He is the founder of the Academy of Personal Development Columbus.',
        es: 'Graduado de la Facultad de Economía de la Universidad de Gdańsk. Se graduó con una maestría con especialización en Comercio Marítimo. Graduado de los estudios de MBA en la Universidad WSB en Gdańsk. Miembro del Consejo de Expertos de la Universidad de Gdańsk. Ha estado asociado con la industria de TFL durante más de 20 años. Desde 2010, dirige con éxito un grupo de empresas, Omida VLS Sp. z o.o., líder de Pomerania en la industria de TFL, que emplea a más de 500 empleados. Es el fundador de la Academia de Desarrollo Personal de Columbus.',
        ro: 'Absolvent al Facultății de Economie a Universității din Gdansk. A absolvit cu specializarea Comerț Maritim. Absolvent al studiilor MBA la Universitatea WSB din Gdańsk. Membru al Consiliului de experți al Universității din Gdansk. Asociat cu industria TFL de peste 20 de ani. Din 2010, conduce cu succes un grup de companii Omida VLS Sp. z o.o., un lider pomeranian în industria TFL, care angajează peste 500 de angajați. El este fondatorul Academiei de Dezvoltare Personală Columbus.',
      },
      image: 'image2',
    },
  ],
}

const Chiefs = () => {
  const { lang } = useLangContext()

  // Use contentArchive for 'es' and 'ro' languages
  if (lang === 'es' || lang === 'ro') {
    return (
      <ChiefSection chiefs={contentArchive} />
    )
  }

  // Use regular content structure for 'en'
  return (
    <>
      <ChiefSection chiefs={content.managment} />
      <ChiefSection chiefs={content.supervisory} />
    </>
  )
}

export default Chiefs
