import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import alt from '../content/alt'
import {
  alpha,
  colors,
  s,
  sGatsbyImage,
  sTextGradient,
  sSubtitle,
  sDesc,
} from '../style'
import H from './H'

const BannerImage = ({ title, subtitle, desc, image, h, square }) => {
  return (
    <div css={sContainer}>
      <div css={[sImage]}>
        {/* <div css={sDimmer} /> */}
        <div css={square && sImageSquare}>
          <GatsbyImage
            style={sGatsbyImage}
            image={getImage(image)}
            alt={title + alt}
            imgStyle={square && { objectPosition: 'center top' }}
          />
        </div>
      </div>
      <div css={sText}>
        <span className='subtitle'>{subtitle}</span>
        <H h={h} className='title'>
          {title}
        </H>
        <p className='desc' dangerouslySetInnerHTML={{ __html: desc }} />
      </div>
    </div>
  )
}

const sContainer = {
  background: `linear-gradient(to right, ${colors.greyDark.concat(
    alpha[100]
  )} 33%, ${colors.greyDarker})`,
  color: 'white',
  borderRadius: '1rem',
  marginTop: '2rem',
  [s.md]: { borderRadius: '2rem', marginTop: '4rem' },
  display: 'flex',
  overflow: 'hidden',
  backVisibility: 'hidden',
  transform: 'translateZ(0)',
  [s.sm_down]: {
    flexDirection: 'column',
  },
}

const sText = {
  [s.md]: { padding: '4rem' },
  padding: '2rem',

  '.title': {
    ...sTextGradient,
    fontSize: '3rem',
    fontWeight: 900,
    marginBottom: '2rem',
    lineHeight: '1.35em',
    marginTop: '1rem',
    [s.md_down]: {
      lineHeight: '1em',
    },
  },

  '.subtitle': {
    ...sSubtitle,
  },

  '.desc': {
    ...sDesc,
    lineHeight: '2em',
    fontSize: '0.875rem',
    [s.sm_down]: {
      fontSize: '0.75rem',
      lineHeight: '1.618em',
      paddingBottom: '1rem',
    },
  },
}

const sImage = {
  width: '100%',
  position: 'relative',
  top: 0,
  left: 0,
  flexShrink: 0,
  borderRadius: 'inherit',
  // paddingRight: '2rem',

  [s.md]: {
    maxWidth: '40%',
    flexBasis: '40%',
  },
  [s.lg]: {
    maxWidth: '33%',
    flexBasis: '33%',
  },
}

const sImageSquare = {
  paddingTop: '100%',
  // [s.sm]: { paddingTop: '56.25%' },
  [s.md]: { paddingTop: '100%' },
}

// const sDimmer = {
//   zIndex: 2,
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   width: '100%',
//   height: '100%',
//   background: `linear-gradient(to right, ${colors.greyDark.concat(
//     alpha[0]
//   )} 75%, ${colors.greyDark.concat(alpha[100])})`,
// }

export default BannerImage
